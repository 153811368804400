import React from 'react';
import styled from 'styled-components';
import { Link as GatsbyLink, graphql } from 'gatsby';

import Seo from '@core/Seo';
import BackLink from '@components/BackLink';
import withModal from '@common/withModal';
import mq, { mqdown } from '@mq';
import { richText, hyphenate, linkHover } from '@mixins';
import renderRichText from '@common/renderRichText';

const TextArticlePage = ({
  modal,
  data: {
    contentfulTextArticle: { author, title, source, date, content },
  },
}) => (
  <>
    <Seo pageTitle={title} />
    {!modal && <BackLink to="/texte" />}
    <Header>
      <Author modal={modal}>{author}</Author>
      <Title>{title}</Title>
      <div>
        {source}, {date}
      </div>
    </Header>
    <Content>{renderRichText(content)}</Content>
  </>
);

const Header = styled.header`
  display: grid;

  row-gap: 0.5rem;
  margin-bottom: var(--space-double);

  ${mq.medium} {
    row-gap: 0.75rem;
    margin-bottom: 4rem;
  }
`;

const Title = styled.h1`
  font: var(--font-h3);
`;

const Author = styled.div`
  margin-right: ${props => props.modal && 'var(--space-double)'};
`;

const Content = styled.div`
  ${richText};
  ${hyphenate};
  font: var(--font-default);
`;

export const query = graphql`
  query ($id: String!) {
    contentfulTextArticle(id: { eq: $id }) {
      author
      title
      source
      date(formatString: "D.M.YYYY")
      content {
        raw
      }
    }
  }
`;

export default withModal(TextArticlePage);
